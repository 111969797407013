import { CommonModule } from '@angular/common';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as effects from './effects';
import * as reducers from './reducers';
import * as guards from './guards';
import * as services from './services';

export const EFFECTS = [effects.OrganizationEffects];

export const IMPORTS = [
  CommonModule,
  StoreModule.forFeature('core', reducers.organizationReducer),
  /** TO BE removed in production build */
  StoreDevtoolsModule.instrument({
    maxAge: 50,
  }),
  EffectsModule.forFeature(EFFECTS),
];

export const SERVICES = [services.AlertService];

export const GUARDS = [guards.OrganizationExistsGuard];

export const EXPORTS = [CommonModule];
