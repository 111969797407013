import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import { delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { OrganizationService } from '../services';
import { Router } from '@angular/router';

@Injectable()
export class OrganizationEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private store: Store<any>,
    private loginService: OrganizationService
  ) {}

  $loadOrganization = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.LoadOrganizationAction),
      switchMap((action) => {
        return forkJoin([
          this.loginService.loadOrganization(action.organization),
        ]).pipe(
          map(([organization]) => {
            if (organization.success) {
              return actions.LoadOrganizationSuccessfulAction({
                organization: organization.data,
              });
            } else {
              return actions.LoadOrganizationFailedAction({
                error: organization.error,
              });
            }
          })
        );
      })
    )
  );
}
