import { createReducer, on } from '@ngrx/store';
import { CoreState } from '../models';

import * as actions from '../actions';

const initialState: CoreState = {
  organization: {
    loading: false,
    data: null,
  },
};

const reducerFce = createReducer(
  initialState,

  on(actions.LoadOrganizationAction, (state, action) => ({
    ...state,
    organization: {
      loading: true,
      data: null,
    },
  })),

  on(actions.LoadOrganizationSuccessfulAction, (state, action) => ({
    ...state,
    organization: {
      loading: false,
      data: action.organization,
    },
  })),
  on(actions.LoadOrganizationFailedAction, (state, action) => ({
    ...state,
    organization: {
      loading: false,
      data: null,
      error: action.error,
    },
  }))
);

export function organizationReducer(state, action) {
  return reducerFce(state, action);
}
