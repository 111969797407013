import { Injectable } from '@angular/core';
import { SpacetiHttpService } from './spaceti-http.service';
import { Envelope } from '@spaceti/models';
import { Observable, of } from 'rxjs';
import { Organization } from '@spaceti/models';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private http: SpacetiHttpService) {}

  public loadOrganization(
    organization: string
  ): Observable<Envelope<Organization>> {
    const url = this.http.getCustomUrl('auth', 'accounts/' + organization);
    return this.http.get<any, Organization>(url, (resp) => resp);
  }
}
