import { Routes } from '@angular/router';
import { QRLoginGuard } from '@spaceti/core/initial';

export const routes: Routes = [
  {
    path: '',
    canLoad: [QRLoginGuard],
    loadChildren: () =>
      import('@spaceti/features/qr/main').then((m) => m.FeaturesQrMainModule),
  },
];
