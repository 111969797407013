import { createAction, props } from '@ngrx/store';
import { type } from '@spaceti/utils';
import { Organization } from '@spaceti/models';

export const OrganizationActionTypes = {
  LoadOrganization: type('[QR core] Load organization'),
  LoadOrganizationSuccessful: type('[QR core] Load organization successful'),
  LoadOrganizationFailed: type('[QR core] Load organization failed'),
};

export const LoadOrganizationAction = createAction(
  OrganizationActionTypes.LoadOrganization,
  props<{ organization: string; returnTo?: string; integration?: string }>()
);

export const LoadOrganizationSuccessfulAction = createAction(
  OrganizationActionTypes.LoadOrganizationSuccessful,
  props<{ organization: Organization }>()
);

export const LoadOrganizationFailedAction = createAction(
  OrganizationActionTypes.LoadOrganizationFailed,
  props<{ error: any }>()
);
