import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { routes } from './../account.routes';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { CoreInitialModule } from '@spaceti/core/initial';
import { CoreQrModule } from '@spaceti/core/qr';
import { HttpClientModule } from '@angular/common/http';
import { CoreCommonModule, LocationService } from '@spaceti/core/common';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreQrModule,
    CoreCommonModule,
    CoreInitialModule,
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      enableTracing: environment.production ? false : true,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private locationService: LocationService) {
    this.locationService.qr = true;
  }
}
